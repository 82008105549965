body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1hjt71s-MuiTypography-root {
    justify-content: flex-end !important;
    display: flex !important;
}

.css-nrdprl-MuiTypography-root {
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    font-weight: bold !important;
}

.css-1qvr50w-MuiTypography-root {
    font-size: 1.2rem !important;
}

.serviceItemTitle:hover {
    font-weight: 500;
}

/* Homepage */
.serviceCategoryDescriptionOdd {
    background: linear-gradient(to right, #0047BB, #41B6E6);
    /* background: linear-gradient(to right, #97A94D, #B2C561); */
}

.serviceCategoryDescriptionEven {
    background: linear-gradient(to right, #41B6E6, #0047BB);
    /* background: linear-gradient(to right, #B2C561, #97A94D); */
}

.linkAvatar {
    background-color: rgba(244, 244, 244, 0.3) !important;
    border: 2px solid white !important;
    color: white;
    width: 70px !important;
    height: 70px !important;
    position: absolute !important;
    top: calc(50% - 35px) !important;
    left: calc(50% - 35px) !important;
    transform: rotateY(0deg) rotate(45deg) !important;
    z-index: 3 !important;
}

.linkAvatar:hover {
    background-color: rgba(244, 244, 244, 0.7) !important;
    border: 3px solid white !important;
    cursor: pointer;
    width: 80px !important;
    height: 80px !important;
    transition: linear 200ms;
    top: calc(50% - 40px) !important;
    left: calc(50% - 40px) !important;
}

.linkAvatar:hover .serviceCategoryLinkIcon {
    font-size: 50px !important;
    transition: linear 200ms;
}

.serviceCategoryLinkIcon {
    font-size: 40px !important;
}

.homepageServiceImage {
    overflow: hidden !important;
}

.homepageServiceImage:hover {
    opacity: 0.9 !important;
    transform: scale(1.05) rotateY(0deg) rotate(1deg) !important;
    overflow: hidden !important;
    transition: linear 200ms;
}

.iconButtonMenuItemStack:hover {
    background: #f3f3f3 !important;
}

.iconButtonMenuItem {
    width: 100% !important;
}

.iconButtonMenuItem:hover {
    background: none !important;
}

.fancyButton {
    background: linear-gradient(to right, #0047BB, #41B6E6);
    /* background: linear-gradient(to right, #1A88C9, #2AB683); */
}

.menuItemActive {
    background: linear-gradient(45deg, #f4f4f4 10%, #f4f4f4 90%);
}

.accordion .css-i4bv87-MuiSvgIcon-root {
    color: white;
}

.css-19kzrtu {
    padding: 0 !important;
}

.breadcrumbLink {
    text-decoration: none;
    font-size: 20px;
    color: inherit;
}

.breadcrumbLink:hover {
    text-decoration: underline !important;
}